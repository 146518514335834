<template>
	<div id="editor-zone-modele" v-if="currentDiapositive >= 0">
		<div class="tabs">
			<div :class="current_tab === 'presentation' ? 'current tab' : 'tab'" @click="change_current_tab('presentation')">
				<span>Global</span>
			</div>
			<div :class="current_tab === 'diapositive' ? 'current tab' : 'tab'" @click="change_current_tab('diapositive')">
				<span>Slide</span>
			</div>
			<div :class="current_tab === 'composant' ? 'current tab' : 'tab'" @click="change_current_tab('composant')">
				<span>Composant</span>
			</div>
			<div :class="current_tab === 'dynamique' ? 'current tab' : 'tab'" @click="change_current_tab('dynamique')">
				<span>Dynamique</span>
			</div>
		</div>
		<div class="tab-content" v-show="current_tab === 'presentation'">
			<div id="presentation-editor" class="editor">
				<div class="form-group">
					<label>Nom du modèle</label>
					<input v-model="presentation.nom" maxlength="30" />
				</div>
				<div class="form-group">
					<label>Type</label>
					<select v-model="presentation.type">
						<option value="slide_vierge">Mise en forme</option>
						<option value="slide">Prêt à l'emploi</option>
					</select>
				</div>
				<div class="form-group">
					<label>Langue</label>
					<select v-model="presentation.langue">
						<option value="english">English</option>
						<option value="français">Français</option>
						<option value="espanol">Espanol</option>
					</select>
					<button class="translate" @click="translate(presentation.langue)">Traduire en {{ presentation.langue }}</button>
				</div>
				<div class="form-group">
					<label>Filiale pour les données dynamiques</label>
					<select v-model="presentation.filiale">
						<option :value="undefined">Choisissez...</option>
						<option v-for="zone in zones" :key="zone.id" :value="[zone.id]">{{ zone.fields.nom_zone }}</option>
					</select>
				</div>
				<div class="form-group">
					<label>Modèle privé</label>
					<small>Cocher pour rendre privé</small>
					<van-switch id="private" v-model="presentation.prive" size="20px" active-color="#e30613" />
				</div>
				<div class="form-group full">
					<label>Tags</label>
					<input v-model="presentation.tags" maxlength="30" />
				</div>
				<div class="form-group select">
					<label>Groupe Mauffrey</label>
					<select v-model="presentation.groupe[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.groupe_mauffrey" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Géographie</label>
					<select v-model="presentation.geographie[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.geographie" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Qualité</label>
					<select v-model="presentation.qualite[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.qualite" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Formation</label>
					<select v-model="presentation.formation[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.formation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Digitalisation</label>
					<select v-model="presentation.digitalisation[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.digitalisation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Métiers</label>
					<select v-model="presentation.prestations[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.metiers" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Filière</label>
					<select v-model="presentation.filieres[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.filieres" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Matériel</label>
					<select v-model="presentation.materiel[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.materiel" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Toolbox</label>
					<select v-model="presentation.toolbox[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.toolbox" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group select">
					<label>Procédures</label>
					<select v-model="presentation.procedures[0]">
						<option :value="null">Choisissez...</option>
						<option v-for="item in categories.procedures" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
				<div class="form-group">
					<label>Repères </label>
					<van-checkbox id="showhidegrille" v-model="grilled" checked-color="#e30613" @click="toggleGrille()">
						<span style="font-size: 12px">Masquer la grille</span>
					</van-checkbox>
				</div>
				<!-- <div class="form-group">
					<label>Logo client</label>
					<small>En ajoutant un logo client, celui-ci sera placé automatiquement sur toutes les diapositives</small>
					<input id="loadLogoEditor" v-show="!loading" type="file" accept=".png, .jpg, .jpeg" @change="uploadLogoClient" />
					<div id="previewLogoClient" v-if="presentation.logo_client && presentation.logo_client[0].url">
						<img :src="presentation.logo_client[0].url" :alt="presentation.nom_client" :title="presentation.nom_client" />
					</div>
					<label id="labelloadLogoEditor" for="loadLogoEditor">
						<span v-if="!loading">Choisir un logo</span>
						<span v-if="loading">Dépôt en cours...</span>
					</label>
				</div> -->
			</div>
		</div>
		<div class="tab-content" v-show="current_tab === 'diapositive'">
			<div v-if="currentDiapositive != null">
				<div class="editor" id="slide-editor">
					<div class="subcontent">
						<div class="form-group full">
							<label>Nom de la slide</label>
							<input type="text" v-model="presentation.slides[currentDiapositive].name" />
						</div>

						<div class="form-group full">
							<label>Couleur de la barre latérale</label>
							<div class="couleurs">
								<div class="carre" style="background: #e30613" @click="updateBarreColor('#e30613')"></div>
								<div class="carre" style="background: #000000" @click="updateBarreColor('#000000')"></div>
								<div class="carre" style="background: #FFFFFF" @click="updateBarreColor('#FFFFFF')"></div>
							</div>
						</div>
						<div class="form-group full">
							<label>Couleur de fond</label>
							<div class="couleurs">
								<div class="carre" style="background: #ffffff" @click="updateSlideFillColor('#FFFFFF')"></div>
								<div class="carre" style="background: #e30613" @click="updateSlideFillColor('#e30613')"></div>
								<div class="carre" style="background: #000000" @click="updateSlideFillColor('#000000')"></div>
								<div class="carre" style="background: #f6f6f6" @click="updateSlideFillColor('#f6f6f6')"></div>
							</div>
						</div>

						<div class="buttons">
							<div class="button deleteElement" @click="removeSlide">
								<i class="fal fa-trash"></i>
							</div>
						</div>
						<div class="content_remplacement form-group full" v-if="currentUser.fields.role.includes('super_administrateur')">
							<label>Importer le contenu d'un modèle existant</label>
							<select @change="modifyContent($event)">
								<option value>Parcourir...</option>
								<option :value="modele.id" v-for="modele in modeles" :key="modele.id">{{ modele.fields.nom }}</option>
							</select>
						</div>
					</div>
				</div>
				<!-- <div class="editor" id="calques-editor">
					<div class="subcontent">
						<div
							class="calque"
							:data-index="index"
							v-for="(calque, index) in presentation.slides[currentDiapositive].composants"
							:key="`diap${currentDiapositive}${index}`"
						>
							<div class="type">{{ calque.type }}</div>
							<div class="main-content" v-if="calque.type === 'text' || calque.type === 'image'">
								<span v-if="calque.type === 'text'"> {{ calque.content }}</span>
								<span v-if="calque.type === 'image'"> URL : {{ calque.path }} </span>
								<span v-if="calque.type === 'image'"> Data : {{ calque.data }} </span>
							</div>
							<div class="remove" @click="removeElement(index)"><i class="fal fa-trash"></i></div>
							<div class="up" @click="goUp(index)"><i class="fal fa-arrow-up"></i></div>
							<div class="down" @click="goDown(index)"><i class="fal fa-arrow-down"></i></div>
						</div>
					</div>
				</div> -->
			</div>
			<div v-else class="not-possible">Sélectionnez d'abord une diapositive pour accéder à ses paramètres</div>
		</div>
		<div class="tab-content" v-show="current_tab === 'composant'">
			<div v-if="currentComposant.index != null">
				<div class="editor" id="text-editor" v-if="currentComposant.index != null && currentComposant.type === 'text'">
					<div class="subcontent">
						<div class="form-group full">
							<label>Contenu</label>
							<vue-editor
								:editorToolbar="customToolbar"
								theme="snow"
								@focus="focusQuill"
								@blur="blurQuill"
								v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].content"
							></vue-editor>
						</div>
						<div class="form-group full toggles">
							<div class="droite">
								<button
									@click="valign('top')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].valign === 'top' ? 'active' : ''"
								>
									<i class="fal fa-arrow-to-top"></i> Haut
								</button>
								<button
									@click="valign('bottom')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].valign === 'bottom' ? 'active' : ''"
								>
									<i class="fal fa-arrow-to-bottom"></i> Bas
								</button>
							</div>
							<div class="gauche switcher">
								<label>Utiliser pour le sommaire</label>
								<van-switch
									v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].useForSommaire"
									size="18"
									active-color="#38cb89"
									inactive-color="#d0d9e7"
								/>
							</div>
						</div>
						<div class="form-group">
							<label>Couleur globale du texte</label>
							<div class="couleurs">
								<div class="carre" style="background: #e30613" @click="updateComposantColor('#e30613')"></div>
								<div class="carre" style="background: #000000" @click="updateComposantColor('#000000')"></div>
								<div class="carre" style="background: #444444" @click="updateComposantColor('#444444')"></div>
								<div class="carre" style="background: #ffffff" @click="updateComposantColor('#FFFFFF')"></div>
								<div class="carre" style="background: #eeeeee" @click="updateComposantColor('#eeeeee')"></div>
							</div>
						</div>

						<div class="form-group half">
							<label>Taille du texte</label>
							<input type="number" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].fontSize" />
						</div>
						<div class="form-group half">
							<label>Interlignage du texte</label>
							<input
								type="number"
								min="0"
								max="3"
								step="0.1"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].lineSpacing"
							/>
						</div>
						<div class="form-group half">
							<label>Marges autour du bloc</label>
							<input type="number" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].margin" />
						</div>
						<div class="form-group half">
							<label>Rotation du bloc</label>
							<input
								type="number"
								min="0"
								max="360"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].rotate"
							/>
						</div>
						<div class="form-group half">
							<label>Position horizontale</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].x" />
						</div>
						<div class="form-group half">
							<label>Position verticale</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].y" />
						</div>
						<div class="form-group half">
							<label>Longueur du bloc</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].w" />
						</div>
						<div class="form-group half">
							<label>Hauteur du bloc</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].h" />
						</div>
						<div class="buttons">
							<div class="button deleteElement" @click="remove()">
								<i class="fal fa-trash"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="editor" id="liste-editor" v-if="currentComposant.index != null && currentComposant.type === 'liste'">
					<h6>Paramètres de la liste</h6>
					<div class="subcontent">
						<div class="form-group full">
							<label>Puces</label>
							<div class="laliste">
								<li v-for="(puce, index) in presentation.slides[currentDiapositive].composants[currentComposant.index].puces" :key="index">
									<input type="text" class="totake" :value="puce.text" @change="updateListeContent" />
									<span class="delete" @click="removeListItem"><i class="fal fa-trash"></i></span>
								</li>
								<li>
									<input type="text" v-model="newListItem" placeholder="Ajouter votre texte ici" @change="addListeContent()" />
								</li>
							</div>
						</div>
						<div class="form-group full">
							<label>Couleur</label>
							<div class="couleurs">
								<div class="carre" style="background: #e30613" @click="updateComposantColor('#e30613')"></div>
								<div class="carre" style="background: #000000" @click="updateComposantColor('#000000')"></div>
								<div class="carre" style="background: #444444" @click="updateComposantColor('#444444')"></div>
								<div class="carre" style="background: #ffffff" @click="updateComposantColor('#FFFFFF')"></div>
								<div class="carre" style="background: #eeeeee" @click="updateComposantColor('#eeeeee')"></div>
							</div>
						</div>
						<div class="form-group full">
							<label>Fond</label>
							<div class="couleurs">
								<div class="carre" style="background: transparent" @click="updateComposantBgColor('')"></div>
								<div class="carre" style="background: #e30613" @click="updateComposantBgColor('#e30613')"></div>
								<div class="carre" style="background: #000000" @click="updateComposantBgColor('#000000')"></div>
								<div class="carre" style="background: #444444" @click="updateComposantBgColor('#444444')"></div>
								<div class="carre" style="background: #ffffff" @click="updateComposantBgColor('#FFFFFF')"></div>
								<div class="carre" style="background: #eeeeee" @click="updateComposantBgColor('#eeeeee')"></div>
							</div>
						</div>
						<div class="form-group">
							<label>Style</label>
							<select @change="applyStyle">
								<option value>Style prédéfini</option>
								<option :value="JSON.stringify(titre_principal)">Titre principal</option>
								<option :value="JSON.stringify(titre_secondaire)">Titre secondaire</option>
								<option :value="JSON.stringify(corps_de_text)">Corps de texte</option>
								<option :value="JSON.stringify(mise_en_avant)">Mise en avant</option>
								<option :value="JSON.stringify(citation)">Citation</option>
							</select>
						</div>
						<div class="form-group full toggles">
							<div class="droite">
								<button
									@click="toggleBold()"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].bold === true ? 'active' : ''"
								>
									<i class="fas fa-bold"></i>
								</button>
								<button
									@click="toggleItalic()"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].italic === true ? 'active' : ''"
								>
									<i class="fal fa-italic"></i>
								</button>
							</div>
						</div>
						<div class="form-group full toggles">
							<div class="droite">
								<button
									@click="align('left')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].align === 'left' ? 'active' : ''"
								>
									<i class="fal fa-align-left"></i>
								</button>
								<button
									@click="align('center')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].align === 'center' ? 'active' : ''"
								>
									<i class="fal fa-align-center"></i>
								</button>
								<button
									@click="align('right')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].align === 'right' ? 'active' : ''"
								>
									<i class="fal fa-align-right"></i>
								</button>
							</div>
							<div class="gauche">
								<button
									@click="valign('top')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].valign === 'top' ? 'active' : ''"
								>
									<i class="fal fa-arrow-to-top"></i>
								</button>
								<button
									@click="valign('middle')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].valign === 'middle' ? 'active' : ''"
								>
									<i class="fal fa-sign-in"></i>
								</button>
								<button
									@click="valign('bottom')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].valign === 'bottom' ? 'active' : ''"
								>
									<i class="fal fa-arrow-to-bottom"></i>
								</button>
							</div>
						</div>
						<div class="form-group half">
							<label>Taille</label>
							<input type="number" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].fontSize" />
						</div>
						<div class="form-group half">
							<label>Interligne</label>
							<input
								type="number"
								min="0"
								max="3"
								step="0.1"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].lineSpacing"
							/>
						</div>
						<div class="form-group half">
							<label>Marge</label>
							<input type="number" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].margin" />
						</div>
						<div class="form-group half">
							<label>Rotation</label>
							<input
								type="number"
								min="0"
								max="360"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].rotate"
							/>
						</div>
						<div class="form-group half">
							<label>x</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].x" />
						</div>
						<div class="form-group half">
							<label>y</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].y" />
						</div>
						<div class="form-group half">
							<label>Longueur</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].w" />
						</div>
						<div class="form-group half">
							<label>Hauteur</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].h" />
						</div>
						<h6><br />Aide à la mise en forme</h6>
						<div class="form-group">
							<label>Largeur</label>
							<select v-model="largeur" @change="changeWidth()">
								<option value="0">Choisissez</option>
								<option value="1">1 colonne</option>
								<option value="2">2 colonnes</option>
								<option value="3">3 colonnes</option>
								<option value="4">4 colonnes</option>
								<option value="5">5 colonnes</option>
								<option value="6">6 colonnes</option>
								<option value="7">7 colonnes</option>
								<option value="8">8 colonnes</option>
							</select>
						</div>
						<div class="form-group">
							<label>Hauteur</label>
							<select v-model="hauteur" @change="changeHeight()">
								<option value="0">Choisissez</option>
								<option value="1">1 ligne</option>
								<option value="2">2 lignes</option>
								<option value="3">3 lignes</option>
								<option value="4">4 lignes</option>
								<option value="5">5 lignes</option>
								<option value="6">6 lignes</option>
								<option value="7">7 lignes</option>
							</select>
						</div>
						<div class="buttons">
							<div class="button deleteElement" @click="remove()">
								<i class="fal fa-trash"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="editor" id="shape-editor" v-if="currentComposant.index != null && currentComposant.type === 'shape'">
					<div class="subcontent">
						<div class="form-group full">
							<label>Remplissage</label>
							<div class="couleurs">
								<div class="carre" style="background: #e30613" @click="updateShapeColor('#e30613')"></div>
								<div class="carre" style="background: #000000" @click="updateShapeColor('#000000')"></div>
								<div class="carre" style="background: #444444" @click="updateShapeColor('#444444')"></div>
								<div class="carre" style="background: #ffffff" @click="updateShapeColor('#FFFFFF')"></div>
								<div class="carre" style="background: #f6f6f6" @click="updateShapeColor('#f6f6f6')"></div>

								<div class="carre" style="background: #eeeeee" @click="updateShapeColor('#eeeeee')"></div>
							</div>
						</div>
						<div class="form-group full">
							<label>Couleur de bordure</label>
							<div class="couleurs">
								<div class="carre" style="background: #e30613" @click="updateShapeBorderColor('#e30613')"></div>
								<div class="carre" style="background: #000000" @click="updateShapeBorderColor('#000000')"></div>
								<div class="carre" style="background: #444444" @click="updateShapeBorderColor('#444444')"></div>
								<div class="carre" style="background: #ffffff" @click="updateShapeBorderColor('#FFFFFF')"></div>
								<div class="carre" style="background: #f6f6f6" @click="updateShapeBorderColor('#f6f6f6')"></div>
								<div class="carre" style="background: #eeeeee" @click="updateShapeBorderColor('#eeeeee')"></div>
							</div>
						</div>
						<div class="form-group">
							<label>Transparence (%)</label>
							<input
								type="number"
								min="0"
								max="100"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].fill.transparency"
							/>
						</div>
						<div class="form-group">
							<label>Rotation (deg°)</label>
							<input
								type="number"
								min="0"
								max="360"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].rotate"
							/>
						</div>
						<div class="form-group">
							<label>Rayon de bordure</label>
							<input
								type="number"
								min="0"
								max="180"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].rectRadius"
							/>
						</div>
						<div class="form-group">
							<label>Taille de bordure</label>
							<input
								type="number"
								step="1"
								min="0"
								max="10"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].line.width"
							/>
						</div>

						<div class="form-group half">
							<label>Position horizontale</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].x" />
						</div>
						<div class="form-group half">
							<label>Position verticale</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].y" />
						</div>
						<div class="form-group half">
							<label>Longueur</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].w" />
						</div>
						<div class="form-group half">
							<label>Hauteur</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].h" />
						</div>
						<div class="form-group full">
							<label>Cliquer à travers</label>
							<em>Vous ne serez plus en mesure de sélectionner le composant sur l'éditeur</em>
							<van-switch
								v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].click_a_travers"
								size="18"
								active-color="#e30613"
								inactive-color="#d0d9e7"
							/>
						</div>
						<div class="form-group toggles specialimage" v-if="completeCurrentComposant.shadow">
							<div class="gauche">
								<label style="width: 100%; flex: 0 0 auto">Ombre portée</label>
								<button
									@click="updateBoxShadow('none')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].shadow.type === 'none' ? 'active' : ''"
								>
									<i class="fal fa-adjust"></i>
								</button>
								<button
									@click="updateBoxShadow('outer')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].shadow.type === 'outer' ? 'active' : ''"
								>
									<i class="far fa-adjust"></i>
								</button>
							</div>
						</div>
						<h6><br />Aide à la mise en forme</h6>
						<div class="form-group">
							<label>Largeur</label>
							<select v-model="largeur" @change="changeWidth()">
								<option value="0">Choisissez</option>
								<option value="1">1 colonne</option>
								<option value="2">2 colonnes</option>
								<option value="3">3 colonnes</option>
								<option value="4">4 colonnes</option>
								<option value="5">5 colonnes</option>
								<option value="6">6 colonnes</option>
								<option value="7">7 colonnes</option>
								<option value="8">8 colonnes</option>
							</select>
						</div>
						<div class="form-group">
							<label>Hauteur</label>
							<select v-model="hauteur" @change="changeHeight()">
								<option value="0">Choisissez</option>
								<option value="1">1 ligne</option>
								<option value="2">2 lignes</option>
								<option value="3">3 lignes</option>
								<option value="4">4 lignes</option>
								<option value="5">5 lignes</option>
								<option value="6">6 lignes</option>
								<option value="7">7 lignes</option>
							</select>
						</div>
						<div class="buttons">
							<div class="button deleteElement" @click="remove()">
								<i class="fal fa-trash"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="editor" id="image-editor" v-if="currentComposant.index != null && currentComposant.type === 'image'">
					<div class="subcontent">
						<div class="form-group full imagezone">
							<label>Sélectionner l'image</label>
							<em>Requis : fichier PNG ou JPEG, maximum de 5 Mo </em>
							<div
								class="backgroundimagepreview"
								:style="`background-image: url('${presentation.slides[currentDiapositive].composants[currentComposant.index].path}')`"
							></div>
							<input id="loadImageInEditor" v-show="!loading" type="file" accept=".png, .jpg, .jpeg" @change="uploadFile" />
							<label for="loadImageInEditor">
								<i v-if="!loading" class="fal fa-upload"></i>
								<i v-if="loading" class="fal fa-ellipsis-h"></i>
							</label>
							<div class="survols">
								<div class="survol static" @click="openStaticGallery">Ouvrir la galerie</div>
								<div class="survol dynamic" @click="openDynamicGallery">Image dynamique</div>
								<div class="survol delete" @click="remove">Enlever</div>
							</div>
						</div>
						<div class="form-group">
							<label>Position horizontale</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].x" />
						</div>
						<div class="form-group">
							<label>Position verticale</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].y" />
						</div>
						<div class="form-group full toggles specialimage">
							<div class="gauche">
								<label style="width: 100%; flex: 0 0 auto">Remplissage de l'image</label>
								<button
									@click="updateImageSizingType('cover')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].sizing.type === 'cover' ? 'active' : ''"
								>
									<i class="fal fa-expand"></i> Remplir
								</button>
								<button
									@click="updateImageSizingType('contain')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].sizing.type === 'contain' ? 'active' : ''"
								>
									<i class="fal fa-compress"></i> Ajuster
								</button>
								<button
									@click="updateImageSizingType('crop')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].sizing.type === 'crop' ? 'active' : ''"
								>
									<i class="fal fa-compress"></i> Cadrage manuel
								</button>
							</div>
						</div>
						<div class="form-group full">
							<label>Transparence</label>
							<van-slider
								v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].transparency"
								:min="0"
								:max="100"
								:step="1"
								active-color="#e30613"
							/>
						</div>
						<div class="form-group full" v-if="presentation.slides[currentDiapositive].composants[currentComposant.index].hyperlink">
							<label>Lien sur l'image</label>
							<input
								type="url"
								placeholder="https://monsite.fr"
								v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].hyperlink.url"
							/>
						</div>
						<div class="form-group full toggles">
							<van-checkbox id="showhidegrille" v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].rounding" checked-color="#e30613">
								<span style="font-size: 12px">Arrondir les angles</span>
							</van-checkbox>
						</div>
						<div class="form-group">
							<label>Largeur max conseillée</label>
							<div>{{ presentation.slides[currentDiapositive].composants[currentComposant.index].sizing.w * 2 }}px</div>
						</div>
						<div class="form-group">
							<label>Hauteur max conseillée</label>
							<div>{{ presentation.slides[currentDiapositive].composants[currentComposant.index].sizing.h * 2 }}px</div>
						</div>
						<div class="buttons">
							<div class="button deleteElement" @click="remove()">
								<i class="fal fa-trash"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="editor" id="tab-editor" v-if="currentComposant.index != null && currentComposant.type === 'tableau'">
					<h6>Paramètres du tableau</h6>
					<div class="subcontent">
						<div class="form-group">
							<label>Colonnes</label>
							<input type="number" min="1" step="1" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].cols" />
						</div>
						<div class="form-group">
							<label>Lignes</label>
							<input type="number" min="1" step="1" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].rows" />
						</div>
						<div class="form-group full">
							<div class="toggles">
								<label>Mettre en avant la première colonne ? </label>
								<van-switch
									v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].first_colonne_heading"
									size="18"
									active-color="#e30613"
									inactive-color="#d0d9e7"
								/>
							</div>

							<div class="toggles">
								<label>Mettre en avant la première ligne ? </label>
								<van-switch
									v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].first_row_heading"
									size="18"
									active-color="#e30613"
									inactive-color="#d0d9e7"
								/>
							</div>

							<div class="toggles">
								<label>Mettre en avant la dernière ligne ? </label>
								<van-switch
									v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].last_row_heading"
									size="18"
									active-color="#e30613"
									inactive-color="#d0d9e7"
								/>
							</div>

							<div class="toggles">
								<label>Mettre en avant la dernière colonne ? </label>
								<van-switch
									v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].last_colonne_heading"
									size="18"
									active-color="#e30613"
									inactive-color="#d0d9e7"
								/>
							</div>
						</div>

						<div class="form-group">
							<label>Couleur</label>
							<div class="couleurs">
								<div class="carre" style="background: #e30613" @click="updateComposantColor('#e30613')"></div>
								<div class="carre" style="background: #000000" @click="updateComposantColor('#000000')"></div>
								<div class="carre" style="background: #444444" @click="updateComposantColor('#444444')"></div>
								<div class="carre" style="background: #ffffff" @click="updateComposantColor('#FFFFFF')"></div>
								<div class="carre" style="background: #eeeeee" @click="updateComposantColor('#eeeeee')"></div>
							</div>
						</div>

						<div class="form-group">
								<label>Bordures</label>
								<div class="couleurs">
									<div class="carre" style="background: #e30613" @click="updateComposantBorderColor('#e30613')"></div>
									<div class="carre" style="background: #000000" @click="updateComposantBorderColor('#000000')"></div>
									<div class="carre" style="background: #444444" @click="updateComposantBorderColor('#444444')"></div>
									<div class="carre" style="background: #ffffff" @click="updateComposantBorderColor('#FFFFFF')"></div>
									<div class="carre" style="background: #eeeeee" @click="updateComposantBorderColor('#eeeeee')"></div>
								</div>
							</div>

						<div class="form-group full toggles">
							<div class="droite">
								<button
									@click="align('left')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].align === 'left' ? 'active' : ''"
								>
									<i class="fal fa-align-left"></i>
								</button>
								<button
									@click="align('center')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].align === 'center' ? 'active' : ''"
								>
									<i class="fal fa-align-center"></i>
								</button>
								<button
									@click="align('right')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].align === 'right' ? 'active' : ''"
								>
									<i class="fal fa-align-right"></i>
								</button>
							</div>
							<div class="gauche">
								<button
									@click="valign('top')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].valign === 'top' ? 'active' : ''"
								>
									<i class="fal fa-arrow-to-top"></i>
								</button>
								<button
									@click="valign('middle')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].valign === 'middle' ? 'active' : ''"
								>
									<i class="fal fa-sign-in"></i>
								</button>
								<button
									@click="valign('bottom')"
									:class="presentation.slides[currentDiapositive].composants[currentComposant.index].valign === 'bottom' ? 'active' : ''"
								>
									<i class="fal fa-arrow-to-bottom"></i>
								</button>
							</div>
						</div>
						<div class="form-group half">
							<label>Taille</label>
							<input type="number" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].fontSize" />
						</div>
						<div class="form-group half">
							<label>Interligne</label>
							<input
								type="number"
								min="0"
								max="3"
								step="0.1"
								v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].lineSpacing"
							/>
						</div>
						<div class="form-group half">
							<label>x</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].x" />
						</div>
						<div class="form-group half">
							<label>y</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].y" />
						</div>
						<div class="form-group half">
							<label>Longueur</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].w" />
						</div>
						<div class="form-group half">
							<label>Hauteur</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].h" />
						</div>
						<div class="buttons">
							<div class="button deleteElement" @click="remove()">
								<i class="fal fa-trash"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="editor" id="graph-editor" v-if="currentComposant.index != null && currentComposant.type === 'graph'">
					<h6>Paramètres du graphique</h6>
					<div class="subcontent">
						<div class="form-group full">
							<label>Type de graphique</label>
							<select v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].type_chart">
								<option value="PIE">Doughnut</option>
								<option value="BAR">Histogramme</option>
								<option value="AREA">Aire</option>
								<option value="LINE">Ligne</option>
							</select>
						</div>
						<h5>Données du graphique</h5>
						<div class="form-group full">
							<label>Nom</label>
							<input type="text" v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].data[0].name" />
						</div>
						<div class="form-group full">
							<label>Tableau</label>
							<div class="fake_table">
								<div
									class="item"
									v-for="(el, index) in presentation.slides[currentDiapositive].composants[currentComposant.index].data[0].labels"
									:key="`data${index}label`"
								>
									<input
										type="text"
										maxlength="30"
										v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].data[0].labels[index]"
									/>

									<input
										type="number"
										maxlength="30"
										v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].data[0].values[index]"
									/>

									<div class="deleteDataFromGraph" @click="deleteDataFromGraph({ index: index })">
										<i class="fal fa-minus"></i>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group full">
							<label>Ajout d'une nouvelle donnée</label>
							<form
								class="new_graph"
								@submit.prevent="
									addNewDataToGraph({
										label: new_label,
										value: new_value,
									})
								"
							>
								<input type="text" maxlength="30" v-model="new_label" placeholder="Ma donnée" required />
								<input type="number" maxlength="30" v-model.number="new_value" placeholder="1000" required />
								<button type="submit">Ajouter</button>
							</form>
						</div>
						<div class="form-group full">
							<div class="toggles">
								<label>Afficher la légende ? </label>
								<van-switch
									v-model="presentation.slides[currentDiapositive].composants[currentComposant.index].showLegend"
									size="18"
									active-color="#e30613"
									inactive-color="#d0d9e7"
								/>
							</div>
						</div>
						<div class="form-group half">
							<label>x</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].x" />
						</div>
						<div class="form-group half">
							<label>y</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].y" />
						</div>
						<div class="form-group half">
							<label>Longueur</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].w" />
						</div>
						<div class="form-group half">
							<label>Hauteur</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].h" />
						</div>
						<h6><br />Aide à la mise en forme</h6>
						<div class="form-group">
							<label>Largeur</label>
							<select v-model="largeur" @change="changeWidth()">
								<option value="0">Choisissez</option>
								<option value="1">1 colonne</option>
								<option value="2">2 colonnes</option>
								<option value="3">3 colonnes</option>
								<option value="4">4 colonnes</option>
								<option value="5">5 colonnes</option>
								<option value="6">6 colonnes</option>
								<option value="7">7 colonnes</option>
								<option value="8">8 colonnes</option>
							</select>
						</div>
						<div class="form-group">
							<label>Hauteur</label>
							<select v-model="hauteur" @change="changeHeight()">
								<option value="0">Choisissez</option>
								<option value="1">1 ligne</option>
								<option value="2">2 lignes</option>
								<option value="3">3 lignes</option>
								<option value="4">4 lignes</option>
								<option value="5">5 lignes</option>
								<option value="6">6 lignes</option>
								<option value="7">7 lignes</option>
							</select>
						</div>
						<div class="buttons">
							<div class="button deleteElement" @click="remove()">
								<i class="fal fa-trash"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="editor" id="video-editor" v-if="currentComposant.index != null && currentComposant.type === 'online'">
					<h6>Paramètres de la vidéo</h6>
					<div class="subcontent">
						<div class="form-group">
							<label>x</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].x" />
						</div>
						<div class="form-group">
							<label>y</label>
							<input type="number" step="10" v-model.number="presentation.slides[currentDiapositive].composants[currentComposant.index].y" />
						</div>
						<div class="form-group">
							<label>Longueur</label>
							<input type="number" :value="presentation.slides[currentDiapositive].composants[currentComposant.index].w" />
						</div>
						<div class="form-group">
							<label>Hauteur</label>
							<input type="number" :value="presentation.slides[currentDiapositive].composants[currentComposant.index].h" />
						</div>
						<div class="form-group">
							<label>URL de la vidéo <span>YouTube seulement</span></label>
							<input type="url" :value="presentation.slides[currentDiapositive].composants[currentComposant.index].link" />
						</div>
						<div class="buttons">
							<div class="button deleteElement" @click="remove()">
								<i class="fal fa-trash"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="not-possible">Sélectionnez d'abord un composant pour accéder à ses paramètres</div>
		</div>
		<div class="tab-content" v-show="current_tab === 'dynamique'">
			<form class="search_in_globale" @submit.prevent="filter_globales">
				<label for="globale_interest">Rechercher une valeur dynamique</label>
				<input type="text" v-model="globale_interest" id="globale_interest" @keyup="filter_globales" />
				<button type="submit"><i class="fa-regular fa-search"></i></button>
			</form>
			<div class="liste">
				<em
					>Utilisez les textes dynamiques entre crochets dans vos slides. Au moment de la génération, le texte entre crochet sera remplacé par la valeur
					renvoyée.</em
				>
				<div class="globale">
					<strong>[sommaire]</strong>
					<small><span>Valeur renvoyée :</span> Le sommaire</small>
					<blockquote>Affiche un sommaire en prenant tous les éléments cochés "Utiliser pour le sommaire"</blockquote>
				</div>
				<div class="globale">
					<strong>[nom_client]</strong>
					<small><span>Valeur renvoyée :</span> {{ presentation.nom_client }}</small>
					<blockquote>Affiche un sommaire en prenant tous les éléments cochés "Utiliser pour le sommaire"</blockquote>
				</div>
				<div class="globale" v-for="g in globales" :key="g.id">
					<strong>[{{ g.fields.code_court }}]</strong>
					<small><span>Valeur renvoyée :</span> {{ g.fields.valeur_retournee }}</small>
					<blockquote>{{ g.fields.description }}</blockquote>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import slugify from "slugify";

import { Dialog } from "vant";
export default {
	name: "Editor",
	components: { VueEditor },
	data() {
		return {
			grilled: true,
			globale_interest: "",
			customToolbar: [
				[{ header: [false, 1, 2, 3] }],
				["bold", "italic", "underline", "link"],
				[{ align: "" }, { align: "center" }, { align: "right" }, { script: "sub" }, { script: "super" }],
				[{ color: ["#e30613", "#000000", "#444444", "#ffffff", "#9F9F9F"] }],
				[{ list: "ordered" }, { list: "bullet" }],
				["blockquote"],
				["clean"],
			],
			new_value: 0,
			new_label: "",
			newListItem: "",
			loading: false,
			format_image: "",
			largeur: 0,
			hauteur: 0,
			titre_principal: {
				bold: true,
				fontSize: 18,
				color: "#000000",
				lineSpacing: 1.3,
				italic: false,
			},
			titre_secondaire: {
				bold: true,
				fontSize: 13,
				color: "#000000",
				lineSpacing: 1.2,
				italic: false,
			},
			corps_de_text: {
				fontSize: 8,
				bold: false,
				color: "#444444",
				lineSpacing: 1.4,
				italic: false,
			},
			mise_en_avant: {
				fontSize: 9,
				bold: true,
				color: "#000000",
				lineSpacing: 1.4,
				italic: false,
			},
			citation: {
				fontSize: 8,
				bold: true,
				color: "#e30613",
				lineSpacing: 1.8,
				italic: true,
			},
		};
	},
	methods: {
		filter_globales() {
			let listes = document.querySelectorAll("#editor-zone-modele .liste .globale");

			if (listes) {
				if (this.globale_interest.length > 2) {
					listes.forEach((liste) => {
						liste.classList.add("hidden");
						let description = liste.querySelector("blockquote").innerText + ' ' + liste.querySelector("strong");
						if (description) {
							if (slugify(description).toLowerCase().includes(slugify(this.globale_interest).toLowerCase())) {
								liste.classList.remove("hidden");
							}
						}
					});
				} else {
					listes.forEach((el) => {
						el.classList.remove("hidden");
					});
				}
			}
		},
		modifyContent(e) {
			if (window.confirm("Voulez-vous vraiment importer ce modèle ? Cette action est irréversible")) {
				let modele = this.modeles.find((el) => el.id === e.target.value);
				if (modele) {
					if (modele.fields.fichier_de_config) {
						fetch(modele.fields.fichier_de_config[0].url)
							.then((res) => res.json())
							.then((json) => {
								if (json[0]) {
									this.presentation.slides = json;
								} else {
									this.presentation.slides = [json];
								}
							});
					}
				}
			}
		},
		toggleGrille() {
			let rendu = document.getElementById("rendu");
			rendu.classList.toggle("previewmode");
			//this.grilled = !this.grilled;
		},
		log(e) {
			this.$store.commit("reorganized_slides", { newIndex: e.newIndex, oldIndex: e.oldIndex });
		},
		updateImageSizingType(e) {
			this.$store
				.dispatch("updateImageSizingType", {
					slide: this.currentDiapositive,
					composant: this.currentComposant.index,
					type: e,
				})
				.then(() => {
					this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].x -= 1;
					this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].x += 1;
				});
		},
		updateBoxShadow(e) {
			this.$store.dispatch("updateBoxShadow", {
				slide: this.currentDiapositive,
				composant: this.currentComposant.index,
				type: e,
			});
		},
		handleFilePondInit: function () {},
		toggleNext(e) {
			let subcontent = e.currentTarget.nextSibling;
			if (subcontent) {
				subcontent.classList.toggle("masked");
			}
		},
		align(payload) {
			this.$store.commit("updateElementAlignement", payload);
		},
		openInputFile(el) {
			el.currentTarget.parentNode.nextSibling.click();
		},
		applyStyle(e) {
			if (e.target.value) {
				var new_parameters = JSON.parse(e.target.value);
				this.updateStyle(new_parameters);
			}
		},
		removeSlide() {
			Dialog.confirm({
				title: "Suppression d'un élément",
				message: "Voulez-vous vraiment supprimer cette diapositive ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			})
				.then(() => {
					this.removeElementSlide();
				})
				.catch(() => {
					// on cancel
				});
		},
		remove() {
			this.removeElement();
		},
		uploadLogoClient(e) {
			this.loading = true;
			this.$store
				.dispatch("uploadImage", {
					file: e.target.files[0],
				})
				.then((res) => {
					this.loading = false;
					this.$store.commit("updatePresentationLogo", res);
				})
				.catch((err) => {
					this.loading = false;
					e.target.value = "";
					Dialog.alert({
						title: "Ooooops !",
						message: "Une erreur est survenue pendant le dépôt du logo: <br />" + err,
						confirmButtonText: "OK",
					});
				});
		},
		uploadFile(e) {
			this.loading = true;
			this.$store
				.dispatch("uploadImage", {
					file: e.target.files[0],
				})
				.then((res) => {
					this.loading = false;
					if (res) {
						res.w = res?.meta?.width;
						res.h = res?.meta?.height;
						this.$store.commit("updateElementImage", res);
					}
					e.target.value = "";
				})
				.catch((err) => {
					this.loading = false;
					e.target.value = "";
					Dialog.alert({
						title: "Ooooops !",
						message: "Une erreur est survenue pendant le dépôt de l'image: <br />" + err,
						confirmButtonText: "OK",
					});
				});
		},
		addListeContent() {
			let new_liste = [];
			let liste = document.querySelector("#liste-editor .laliste");
			if (liste) {
				let inputs = liste.querySelectorAll("input.totake");
				if (inputs) {
					inputs.forEach((el) => {
						let item = {
							text: el.value,
						};
						new_liste.push(item);
					});
					let other_item = {
						text: this.newListItem,
					};
					new_liste.push(other_item);
					this.newListItem = "";
					this.updateElementListe(new_liste);
				}
			}
		},
		removeListItem(e) {
			e.currentTarget.parentNode.remove();
			this.updateListeContent();
		},
		updateListeContent() {
			let new_liste = [];
			let liste = document.querySelector("#liste-editor .laliste");
			if (liste) {
				let inputs = liste.querySelectorAll("input.totake");
				if (inputs) {
					inputs.forEach((el) => {
						let item = {
							text: el.value,
						};
						new_liste.push(item);
					});
					this.updateElementListe(new_liste);
				}
			}
		},
		openDynamicGallery() {
			this.showGallery();
		},
		modifyFormat() {
			switch (this.format_image) {
				case "carre":
					this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.h =
						this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.w;
					break;
				case "seize9":
					this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.h = Math.round(
						this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.w * 0.5625
					);
					break;
				case "deuxtiers":
					this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.h = Math.round(
						this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.w * 1.5
					);
					break;
				case "troisdemi":
					this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.h = Math.round(
						this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.w * 0.667
					);
					break;
				case "slide":
					this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.h = 540;
					this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.w = 960;
					break;
				default:
					console.log("Aucune taille prédéfinie imposée");
			}
			this.format_image = "";
		},
		changeWidthImage(e) {
			if (this.largeur > 0) {
				let largeur = this.largeur * 82.5 + (this.largeur - 1) * 20;
				this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.w = largeur;
			}
			this.largeur = 0;
		},
		changeHeightImage(e) {
			if (this.hauteur > 0) {
				let hauteur = this.hauteur * 58;
				this.presentation.slides[this.currentDiapositive].composants[this.currentComposant.index].sizing.h = hauteur;
			}
			this.hauteur = 0;
		},
		changeWidth(e) {
			if (this.largeur > 0) {
				let largeur = this.largeur * 82.5 + (this.largeur - 1) * 20;
				this.updateElementLargeur(largeur);
			}
			this.largeur = 0;
		},
		changeHeight(e) {
			if (this.hauteur > 0) {
				let hauteur = this.hauteur * 58;
				this.updateElementHauteur(hauteur);
			}
			this.hauteur = 0;
		},
		...mapMutations({
			deleteDataFromGraph: "deleteDataFromGraph",
			addNewDataToGraph: "addNewDataToGraph",
			updateStyle: "updateStyle",
			toggleBold: "updateElementWeight",
			toggleItalic: "updateElementStyle",
			toggleUpperCase: "updateElementCase",
			updateElementLargeur: "updateElementLargeur",
			updateElementHauteur: "updateElementHauteur",
			goUp: "upComposant",
			goDown: "downComposant",
			removeElement: "removeElement",
			removeElementSlide: "removeElementSlide",
			change_current_tab: "change_current_tab",
			updateComposantColor: "updateComposantColor",
			updateComposantBorderColor: "updateComposantBorderColor",
			updateComposantBgColor: "updateComposantBgColor",
			updateShapeColor: "updateShapeColor",
			updateTableColor: "updateTableColor",
			updateBarreColor: "updateBarreColor",
			updateSlideFillColor: "updateSlideFillColor",
			updateSlideTextColor: "updateSlideTextColor",
			updateShapeBorderColor: "updateShapeBorderColor",
			valign: "updateElementVerticalAlignement",
			updateElementListe: "updateElementListe",
			showGallery: "showGallery",
			focusQuill: "focusQuill",
			blurQuill: "blurQuill",
		}),
		...mapActions({
			updateSlideBG: "updateSlideBG",
			removeSlideBg: "removeSlideBg",
			openStaticGallery: "openStaticGallery",
			updateImageDecalageX: "updateImageDecalageX",
			updateImageDecalageY: "updateImageDecalageY",
			updateImageZoom: "updateImageZoom",
			translate: "translateModele"
		}),
	},
	mounted() {},
	computed: {
		globales() {
			let textes = this.$store.state.globales.filter((el) => el.fields.type === "texte");
			let filiale_choisie = this.$store.state.current_presentation.fields.filiale;
			if (filiale_choisie) {
				let zone = this.$store.state.zones.find((el) => el.id === this.$store.state.current_presentation.fields.filiale[0]);
				let final = [];
				textes.forEach((el) => {
					if (el.fields.valeurs) {
						let valeurs = JSON.parse(el.fields.valeurs);
						let jeton_de_valeur = valeurs.filter((item) => item.zone === zone.fields.nom_zone);
						if (jeton_de_valeur && jeton_de_valeur.length > 0) {
							if (jeton_de_valeur[0].valeur != "" || jeton_de_valeur[0].valeur != "X" || jeton_de_valeur[0].valeur != "➜") {
								el.fields.valeur_retournee = jeton_de_valeur[0].valeur;
							}
						}
					}
					final.push(el);
				});
				return final;
			} else {
				return textes;
			}
		},
		...mapGetters({
			current_tab: "current_tab",
			presentation: "currentPresentation",
			currentDiapositive: "currentDiapositive",
			currentComposant: "currentComposant",
			modeles: "modeles",
			globales_texte: "globales_texte",
			currentUser: "currentUser",
			categories: "categories",
			completeCurrentComposant: "completeCurrentComposant",
			zones: "zones",
		}),
	},
	watch: {
		grilled: function (newGrilled, oldGrilled) {
			let rendu = document.getElementById("rendu");
			this.grilled = rendu.classList.contains("previewmode");
		},
	},
};
</script>

<style lang="scss">
#editor-zone-modele {
	width: 300px;
	grid-area: editor;
	transition: all 0.2s ease-out;
	padding: 0px;
	position: fixed;
	top: 70px;
	right: 5px;
	max-height: calc(100vh - 90px);
	border-radius: 10px;
	box-shadow: 0px 10px 20px rgba(black, 0.1);
	background: white;
	overflow: hidden;
	z-index: 8;
	.tabs {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: center;
		background: rgba(black, 0.02);
		.tab {
			flex: 1;
			background: $gris;
			border-bottom: solid 1px rgba(black, 0.05);
			span {
				padding: 12px 12px;
				display: block;
				font-size: 10px;
				font-weight: 500;
				cursor: pointer;
				color: rgba(black, 0.6);
			}
			&:hover {
				span {
					color: black;
				}
			}
			&.current {
				background: white;
				border-bottom: none;
				border-top: solid 1px rgba(black, 0.05);
				border-left: solid 1px rgba(black, 0.05);
				border-right: solid 1px rgba(black, 0.05);
				span {
					color: black;
					font-weight: bold;
				}
			}
		}
	}
	.tab-content {
		background: white;
		display: flex;
		flex-wrap: wrap;
		padding: 12px;
		height: 100%;
		overflow: auto;
		max-height: calc(100vh - 90px);
		-webkit-overflow-scrolling: touch;
		padding-bottom: 60px;
		overscroll-behavior: none;
		.not-possible {
			font-size: 11px;
			line-height: 15px;
			font-weight: normal;
		}
		.liste {
			em {
				color: lighten(black, 40%);
				font-size: 11px;
				line-height: 13px;
				margin-bottom: 8px;
			}
			.globale {
				background: white;
				border-radius: 20px;
				margin: 20px 0;
				display: flex;
				flex-direction: column;
				font-size: 11px;
				line-height: 16px;
				font-weight: 300;
				strong {
					font-size: 12px;
					font-weight: 600;
					letter-spacing: 0.02em;
					margin-bottom: 4px;
				}
				small {
					font-size: 11px;
					color: $vert;
					font-weight: 600;
					margin-bottom: 0px;
				}
				blockquote {
					color: lighten(black, 40%);
				}
				&.hidden {
					display: none;
				}
			}
		}
	}
	.editor {
		padding: 0;
		width: 100%;
		border-bottom: solid 1px $gris;
		h6 {
			color: black;
			font-weight: 500;
			font-size: 13px;
			display: flex;
			align-items: center;
			font-weight: 600;
			letter-spacing: 0.02em;
			margin: 20px 0 10px;
			justify-content: flex-start;
			cursor: pointer;
			i {
				font-size: 14px;
				margin-right: 10px;
			}
		}
		h5 {
			font-size: 13px;
			font-weight: 600;
			margin: 20px 0 5px;
			color: lighten(black, 50%);
		}
		.subcontent {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			&.masked {
				display: none;
			}
		}
		.vue-tags-input {
			border-radius: 20px;
			background: $gris;
			border: none;
			margin: 20px 0 0;
			transition: all 0.3s ease;
			&:hover {
				box-shadow: inset 2px 2px 4px rgba(black, 0.03);
			}
			.ti-input {
				border: none;
				padding: 16px;
				.ti-tag {
					border-radius: 3px;
					background: $vertclair;
					color: $vert;
					font-weight: 500;
					font-size: 12px;
					padding: 8px;
				}
				.ti-new-tag-input-wrapper {
					input {
						background: transparent;
					}
				}
			}
		}
		.form-group {
			display: flex;
			flex: 0 0 50%;
			flex-direction: column;
			align-items: flex-start;
			margin: 8px 0 13px 0;
			padding-right: 8px;
			button.translate {
				background: $bleu;
				color: white;
				padding: 8px 12px;
				border-radius: 4px;
				font-size: 12px;
				font-weight: 500;
				margin-top: 8px;
				transition: all 0.2s ease;
				&:hover {
					background: darken($bleu, 10%);
				}
			}
			&.full {
				flex: 0 0 100%;
			}
			&.half {
				flex: 0 0 50%;
			}
			&.third {
				flex: 0 0 33.333333%;
			}
			.switcher {
				display: flex;
				align-items: center;
				label {
					margin-right: 5px;
					max-width: 100px;
					text-align: right;
				}
			}
			label {
				font-size: 9px;
				font-weight: 500;
				line-height: 12px;
				font-weight: normal;
				color: lighten(black, 20%);
				margin-bottom: 4px;
				text-transform: uppercase;
			}
			.loading {
				color: $bleu;
				text-align: center;
				font-size: 12px;
				font-weight: 500;
				display: block;
				padding: 6px;
				width: 100%;
				i {
					margin-right: 6px;
				}
			}
			&.select {
				width: 100%;
			}
			.imagepreview {
				position: relative;
				background-color: rgba(white, 0.7);
				margin: 8px 0 0px;
				width: 100%;
				border-radius: 4px;
				img {
					width: 100%;
					height: auto;
				}
			}
			.backgroundimagepreview {
				position: relative;
				background-color: rgba(black, 0.1);
				padding: 10px;
				margin: 8px 0 0px;
				width: 100%;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;

				&:after {
					content: "";
					padding-bottom: 56.25%;
					display: block;
				}
			}
			select,
			input,
			textarea {
				font-size: 11px;
				line-height: 11px;
				background: rgba(black, 0.05);
				min-height: 32px;
				padding: 0 12px;
				border-radius: 2px;
				border: none;
				width: 100%;
				text-align: left;
				font-weight: 500;
				&[type="file"] {
					padding-top: 10px;
				}
				&::read-only {
					background: transparent;
					color: rgba(black, 0.6);
					padding: 0;
				}
			}
			.vue-tags-input {
				border-radius: 0;
				margin: 0;
				.ti-input {
					padding: 0;
				}
			}
			textarea {
				min-height: 120px;
				padding: 8px;
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				box-shadow: inset 1px 1px 3px rgba(black, 0.1);
			}
			em {
				color: lighten(black, 40%);
				font-size: 11px;
				line-height: 13px;
				margin-bottom: 8px;
			}
			&.toggles {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				button {
					background: white;
					color: black;
					font-size: 10px;
					padding: 4px 8px;
					min-width: 30px;
					height: 30px;
					text-align: center;
					justify-content: center;
					align-items: center;
					display: flex;
					border-radius: 4px;
					margin-right: 2px;
					gap: 5px;
					&.active {
						background: transparent;
						box-shadow: inset 1px 1px 3px rgba($bleu, 0.3);
					}
					i {
						margin: 0;
						transform: translateY(1px);
					}
				}
				.gauche,
				.droite {
					display: flex;
					align-items: center;
					flex-direction: row;
				}
				&.specialimage {
					display: flex;
					flex-wrap: wrap;
					.gauche {
						flex: 1;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
					}
					.droite {
						flex: 1;
						display: flex;
						flex-direction: column;
					}
				}
			}
			.couleurs {
				display: flex;
				gap: 4px;
				.carre {
					width: 24px;
					border-radius: 4px;
					box-shadow: 1px 1px 3px rgba(black, 0.3);
					height: 24px;
					cursor: pointer;
					transition: all 0.2s ease;
					&:hover {
						transform: translateY(2px);
					}
					&.image {
						background-color: #e5e5f7;
						opacity: 0.8;
						background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 10px), repeating-linear-gradient($rougeclair, $rouge);
						//background: linear-gradient(to top, $vert, $vertclair);
					}
				}
			}
			.laliste {
				width: 100%;
				margin: 5px 0 30px;
				li {
					list-style: none;
					width: 100%;
					display: flex;
					align-items: center;
					background: white;
					padding: 0px 20px 0px 8px;
					margin-bottom: 4px;
					box-shadow: inset 1px 1px 3px rgba(black, 0.1);
					border-radius: 4px;
					input[type="text"] {
						appearance: none;
						background: transparent;
						box-shadow: none;
					}
				}
			}
			.fake_table {
				display: block;

				.item {
					background: $gris;
					display: flex;
					align-items: center;
					margin-bottom: 5px;
					.deleteDataFromGraph {
						width: 16px;
						height: 16px;
						background: $rougeclair;
						cursor: pointer;
						transition: all 0.3s ease;
						color: $rouge;
						margin-left: 3px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						&:hover {
							background: $rouge;
							color: white;
						}
						i {
							transform: translateY(1px);
						}
					}
					input {
						border-radius: 20px;
						margin: 2px;
					}
				}
			}
			.new_graph {
				display: flex;
				align-items: stretch;
				flex-wrap: wrap;
				justify-content: flex-end;
				input {
					border-radius: 20px;
					margin: 2px;
					flex: 0 0 calc(50% - 4px);
				}
				button {
					padding: 10px;
					font-size: 11px;
					margin-top: 5px;
				}
			}
			&.imagezone {
				background: white;
				overflow: hidden;
				position: relative;

				input[type="file"] {
					border: none;
					border-radius: 0px;
					box-shadow: none;
					background: red;
					visibility: hidden;
					& + label {
						font-size: 20px;
						position: absolute;
						top: 192px;
						left: 50%;
						transform: translate(-50%, -20px);
						width: 40px;
						height: 40px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $bleuclair;
						background: $bleu;
						transition: all 0.2s ease;
						cursor: pointer;
						&:hover {
							background: $rouge;
							color: $rougeclair;
							transform: translate(-50%, -16px);
						}
					}
				}
				.survols {
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					font-size: 11px;
					font-weight: 500;
					flex-wrap: wrap;
					gap: 5px;
					.survol {
						flex: 0 0 calc(50% - 2.5px);
						padding: 8px;
						border-radius: 2px;
						text-align: center;
						cursor: pointer;
						background: $bleuclair;
						transition: all 0.3s ease;
						&:hover {
							background: $bleu;
							color: $bleuclair;
						}
						&.static {
							background: $vertclair;
							color: $vert;
							&:hover {
								background: $vert;
								color: $vertclair;
							}
						}
						&.delete {
							background: $rougeclair;
							color: $rouge;
							flex: 0 0 100%;
							&:hover {
								background: $rouge;
								color: $rougeclair;
							}
						}
					}
				}
			}
			.sliders {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 10px;
				& > div {
					padding: 2px 10px 0px;
					display: flex;
					flex-direction: column;
					label {
						margin-bottom: 0px;

						display: block;
						text-transform: none;
						text-align: right;
					}
					input[type="range"] {
						width: 100%;
						margin: 0;
						background-color: transparent;
						box-shadow: none;
						min-height: none;
						-webkit-appearance: none;
					}
					input[type="range"]:focus {
						outline: none;
					}
					input[type="range"]::-webkit-slider-runnable-track {
						background: rgba(177, 50, 0, 0.2);
						border: 0;
						border-radius: 2.2px;
						width: 100%;
						height: 2.1px;
						cursor: pointer;
					}
					input[type="range"]::-webkit-slider-thumb {
						margin-top: -5.95px;
						width: 14px;
						height: 14px;
						background: rgba(255, 55, 56, 0.93);
						border: 0px solid rgba(129, 30, 0, 0);
						border: 0;
						border-radius: 28px;
						cursor: pointer;
						-webkit-appearance: none;
					}
					input[type="range"]:focus::-webkit-slider-runnable-track {
						background: #cb3900;
					}
					input[type="range"]::-moz-range-track {
						background: rgba(177, 50, 0, 0.2);
						border: 0;
						border-radius: 2.2px;
						width: 100%;
						height: 2.1px;
						cursor: pointer;
					}
					input[type="range"]::-moz-range-thumb {
						width: 14px;
						height: 14px;
						background: rgba(255, 55, 56, 0.93);
						border: 0px solid rgba(129, 30, 0, 0);
						border: 0;
						border-radius: 28px;
						cursor: pointer;
					}
					input[type="range"]::-ms-track {
						background: transparent;
						border-color: transparent;
						border-width: 8.45px 0;
						color: transparent;
						width: 100%;
						height: 2.1px;
						cursor: pointer;
					}
					input[type="range"]::-ms-fill-lower {
						background: #982b00;
						border: 0;
						border-radius: 4.4px;
					}
					input[type="range"]::-ms-fill-upper {
						background: rgba(177, 50, 0, 0.2);
						border: 0;
						border-radius: 4.4px;
					}
					input[type="range"]::-ms-thumb {
						width: 14px;
						height: 14px;
						background: rgba(255, 55, 56, 0.93);
						border: 0px solid rgba(129, 30, 0, 0);
						border: 0;
						border-radius: 28px;
						cursor: pointer;
						margin-top: 0px;
						/*Needed to keep the Edge thumb centred*/
					}
					input[type="range"]:focus::-ms-fill-lower {
						background: rgba(177, 50, 0, 0.2);
					}
					input[type="range"]:focus::-ms-fill-upper {
						background: #cb3900;
					}
					/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
					@supports (-ms-ime-align: auto) {
						/* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
						input[type="range"] {
							margin: 0;
							/*Edge starts the margin from the thumb, not the track as other browsers do*/
						}
					}
				}
			}
		}
		.buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin-top: 10px;
			.button {
				margin: 6px;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $gris2;
				display: flex;
				align-items: center;
				justify-content: center;
				i {
					font-size: 16px;
					margin: 0;
					padding: 0;
				}
				&.deleteElement {
					background: $rougeclair;
					color: $rouge;
					&:hover {
						color: white;
						background: $rouge;
					}
				}
				&.duplicateElement {
					background: $jauneclair;
					color: $jaune;
					&:hover {
						color: white;
						background: $jaune;
					}
				}
				&.modeleElement {
					background: $bleuclair;
					color: $bleu;
					&:hover {
						color: white;
						background: $bleu;
					}
				}
			}
		}
		#quill-container {
			height: auto;
			background: #ddd;
			text-shadow: 1px 1px 0px #999;
			.ql-editor {
				font-size: 12px;
				font-family: "Arial";
			}
		}
		strong {
			font-weight: bold;
		}
	}
	#calques-editor {
		margin-top: 30px;
		.subcontent {
			display: flex;
			flex-direction: column-reverse;
			.calque {
				background: white;
				margin-bottom: 1px;
				border-radius: 2px;
				padding: 5px;
				font-size: 10px;
				display: flex;
				align-items: center;
				width: 100%;
				.type {
					flex: 1;
					font-weight: bold;
				}
				.main-content {
					flex: 2;
					color: #555;
					font-size: 10px;
					font-style: italic;
					text-transform: none;
					overflow: hidden;
					height: 11px;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.remove,
				.up,
				.down {
					padding: 3px;
					flex: 0 0 auto;
					transition: all 0.3s ease;
					&:hover {
						color: $rouge;
					}
				}
			}
		}
	}
	#presentation-editor {
		.form-group {
			margin-bottom: 15px;
			small {
				font-size: 11px;
				line-height: 14px;
				margin: 2px 0 6px;
				color: lighten(black, 50%);
			}
		}
		#loadLogoEditor {
			display: none;
		}
		#previewLogoClient {
			margin: 10px 0;
			img {
				max-width: 90px;
				height: auto;
			}
		}
		#labelloadLogoEditor {
			background: $rouge;
			color: $rougeclair;
			padding: 8px 16px;
			border-radius: 4px;
			transition: all 0.3s ease;
			cursor: pointer;
			margin-top: 5px;
			font-weight: 600;
			letter-spacing: 0.5px;
			&:hover {
				transform: translateY(3px);
				background: darken($rouge, 5%);
			}
		}
	}
	#ordering_editor {
		p {
			margin: 0 15px;
			font-size: 12px;
		}
		.ordering_list {
			padding: 15px;
			.item {
				padding: 8px;
				margin-bottom: 3px;
				background: white;
				border-radius: 5px;
				display: flex;
				font-size: 12px;
				cursor: move;
				align-items: center;
				justify-content: space-between;
				transition: all 0.3s ease;
				&:hover {
					background: $rougeclair;
				}
				span {
					&:first-child {
						flex: 1;
						font-weight: 500;
					}
					&:last-child {
						flex: 2;
						text-align: right;
						height: 14px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
	.search_in_globale {
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;
		margin-bottom: 15px;
		label {
			font-size: 11px;
			color: #555;
			display: block;
			padding-bottom: 10px;
			font-weight: 500;
		}
		input[type="text"] {
			height: 40px;
			border: none;
			border-radius: 5px;
			width: 100%;
			padding: 0 15px;
			font-size: 14px;
			box-shadow: 2px 2px 12px rgba(black, 0.2);
		}
		button[type="submit"] {
			position: absolute;
			right: 0;
			background: none;
			padding: 0;
			color: #e5e5f7;
			top: 30px;
		}
	}
}
</style>
